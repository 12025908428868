import styling from "Services/styling";

export default {
	wrapper: {
		position: "absolute",
		top: "50%",
		right: 0,
		height: 644,
		width: 757,
		...styling.mixins.translate(0, "-50%"),
	},
	image: (
		{
			childImageSharp: {
				original: { src, height, width },
			},
		},
		style
	) => ({
		position: "absolute",
		height: height / 2,
		width: width / 2,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center, center",
		backgroundSize: `${width / 2}px ${height / 2}px`,
		backgroundImage: `url(${src})`,
		...styling.mixins.boxShadow(10, 10, 40, 0, styling.colors.boxShadow),
		...style,
	}),
};
