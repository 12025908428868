import styling from "Services/styling";

export default {
	wrapper: {
		alignItems: "start",
		"& > *": {
			width: "100%",
		},
	},
	paddingWrapper: {
		position: "relative",
		zIndex: 1,
		...styling.mixins.padding(0, 20),
		[styling.sizes.breakpoints.medium]: {
			...styling.mixins.padding(0, 60),
		},
	},
	headingWrapper: {
		position: "relative",
	},
	heading: {
		fontSize: "5rem",
		lineHeight: "5rem",
		fontWeight: 700,
		position: "relative",
		zIndex: 1,
		...styling.mixins.margin(0),
		[styling.sizes.breakpoints.small]: {
			fontSize: "7rem",
			lineHeight: "7rem",
		},
		[styling.sizes.breakpoints.medium]: {
			width: "50%",
			fontSize: "10rem",
			lineHeight: "10rem",
			letterSpacing: "-0.15rem",
		},
	},
	orderHint: {
		display: "block",
		position: "absolute",
		top: "50%",
		left: -40,
		fontSize: "10rem",
		lineHeight: "10rem",
		fontWeight: 900,
		color: styling.colors.backgroundCircle,
		...styling.mixins.translate(0, "-50%"),
		[styling.sizes.breakpoints.small]: {
			fontSize: "15rem",
			lineHeight: "15rem",
			letterSpacing: "-0.15rem",
		},
		[styling.sizes.breakpoints.medium]: {
			fontSize: "20rem",
			lineHeight: "20rem",
			letterSpacing: "-0.3rem",
		},
	},
	arrows: {
		position: "relative",
		height: 140,
		zIndex: 2,
		lineHeight: 0,
		...styling.mixins.padding(50, 0, 0),
	},
	arrow: {
		display: "inline-block",
		...styling.utils.maxRounded,
		...styling.mixins.border([1, "solid", styling.colors.linkDefault]),
		...styling.mixins.padding(8),
		...styling.mixins.transition("border-color"),
		...styling.mixins.margin(0, 10, 0, 3),
		":hover, :focus, :active": {
			...styling.mixins.border([1, "solid", styling.colors.linkInteract]),
			path: {
				fill: styling.colors.linkInteract,
			},
		},
	},
	strap: {
		fontSize: "2rem",
		lineHeight: "2.6rem",
		maxWidth: 260,
		[styling.sizes.breakpoints.small]: {
			height: "7.8rem",
		},
	},
};
