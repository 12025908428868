import { graphql } from "gatsby";
import React from "react";

import PageProductContent from "Components/PageProductContent";
import SEO from "Components/SEO";
import Wrapper from "Components/Wrapper";

const ProductPage = ({
	data: { content, header, images, footer, legal, loginResorts, products },
	pageContext: { langKey },
}) => (
	<Wrapper
		lang={langKey}
		footer={footer}
		header={header}
		legal={legal.edges}
		loginResorts={loginResorts.edges}
		pageType="product"
		products={products.edges}>
		<SEO
			description={
				(content.description && content.description.description) || ""
			}
			lang={langKey}
			title={content.title}
		/>
		<PageProductContent
			{...content}
			images={images.edges}
			lang={langKey}
			products={products.edges}
		/>
	</Wrapper>
);

export default ProductPage;

export const query = graphql`
	query productPageQuery($slug: String, $langKey: String) {
		content: contentfulProduct(
			node_locale: { eq: $langKey }
			slug: { eq: $slug }
		) {
			title
			slug
			strap
			image {
				title
				fluid(maxWidth: 1200) {
					sizes
					src
					srcSet
				}
			}
			keyFeatures {
				...KeyFeatureQuery
			}
			orderHint
			page_product {
				...PageProductQuery
			}
			learnMore {
				json
			}
			relatedProducts {
				title
				slug
				orderHint
			}
		}
		images: allFile(filter: { relativeDirectory: { eq: $slug } }) {
			edges {
				node {
					name
					childImageSharp {
						original {
							height
							src
							width
						}
					}
				}
			}
		}
		header: contentfulHeader(node_locale: { eq: $langKey }) {
			...HeaderQuery
		}
		footer: contentfulFooter(node_locale: { eq: $langKey }) {
			...FooterQuery
		}
		loginResorts: allContentfulResortLogin(
			sort: { fields: title }
			filter: { node_locale: { eq: $langKey } }
		) {
			edges {
				node {
					slug
					title
				}
			}
		}
		products: allContentfulProduct(
			filter: { node_locale: { eq: $langKey } }
			sort: { fields: orderHint }
		) {
			edges {
				node {
					...ProductsMenuQuery
				}
			}
		}
		legal: allContentfulPagePlain(
			filter: { node_locale: { eq: $langKey } }
			sort: { fields: slug }
		) {
			edges {
				node {
					...PlainPagesMenuQuery
				}
			}
		}
	}
`;
