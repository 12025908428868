import { keyframes } from "@emotion/core";

import styling from "Services/styling";

const spinKeyframes = {
	"counterspin-0-1": keyframes`
		0% {
			transform: rotate(40deg);
		}
		100% {
			transform: rotate(-320deg);
		}
	`,
	"spin-0-1": keyframes`
		0% {
			transform: rotate(-40deg) translate(0, -270px);
		}
		100% {
			transform: rotate(320deg) translate(0, -270px);
		}
	`,
	"counterspin-0-2": keyframes`
		0% {
			transform: rotate(40deg);
		}
		100% {
			transform: rotate(-320deg);
		}
	`,
	"spin-0-2": keyframes`
		0% {
			transform: rotate(-40deg) translate(0, -270px);
		}
		100% {
			transform: rotate(320deg) translate(0, -270px);
		}
	`,
	"counterspin-1-2": keyframes`
		0% {
			transform: rotate(-160deg);
		}
		100% {
			transform: rotate(-520deg);
		}
	`,
	"spin-1-2": keyframes`
		0% {
			transform: rotate(160deg) translate(0, -270px);
		}
		100% {
			transform: rotate(520deg) translate(0, -270px);
		}
	`,
	"counterspin-0-3": keyframes`
		0% {
			transform: rotate(60deg);
		}
		100% {
			transform: rotate(-300deg);
		}
	`,
	"spin-0-3": keyframes`
		0% {
			transform: rotate(-60deg) translate(0, -270px);
		}
		100% {
			transform: rotate(300deg) translate(0, -270px);
		}
	`,
	"counterspin-1-3": keyframes`
		0% {
			transform: rotate(-60deg);
		}
		100% {
			transform: rotate(-420deg);
		}
	`,
	"spin-1-3": keyframes`
		0% {
			transform: rotate(60deg) translate(0, -270px);
		}
		100% {
			transform: rotate(420deg) translate(0, -270px);
		}
	`,
	"counterspin-2-3": keyframes`
		0% {
			transform: rotate(-160deg);
		}
		100% {
			transform: rotate(-520deg);
		}
	`,
	"spin-2-3": keyframes`
		0% {
			transform: rotate(160deg) translate(0, -270px);
		}
		100% {
			transform: rotate(520deg) translate(0, -270px);
		}
	`,
};

export default {
	title: {
		fontSize: "3.2rem",
		lineHeight: "3.8rem",
		textAlign: "center",
		fontWeight: 700,
		u: {
			color: styling.colors.primary,
		},
	},
	sun: {
		content: '""',
		position: "absolute",
		height: 135,
		width: 135,
		top: "50%",
		left: "50%",
		color: styling.colors.textOnPrimary,
		display: "none",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		zIndex: 1,
		textAlign: "center",
		fontSize: "2rem",
		lineHeight: "2rem",
		fontWeight: 700,
		...styling.mixins.translate("-50%", "-50%"),
		...styling.utils.maxRounded,
		[styling.sizes.breakpoints.medium]: {
			display: "flex",
		},
	},
	orbitWrapper: {
		position: "relative",
		[styling.sizes.breakpoints.medium]: {
			height: 840,
			width: "840px !important",
			overflow: "hidden",
			...styling.mixins.margin(90, "auto", 0),
			":hover > div, :hover a": {
				animationPlayState: "paused",
			},
			":before": {
				content: '""',
				position: "absolute",
				height: 520,
				width: 520,
				top: "50%",
				left: "50%",
				...styling.mixins.translate("-50%", "-50%"),
				...styling.mixins.border([1, "solid", styling.colors.orbitBorder]),
				...styling.utils.maxRounded,
			},
			":after": {
				content: '""',
				position: "absolute",
				height: 135,
				width: 135,
				top: "50%",
				left: "50%",
				backgroundColor: styling.colors.primary,
				...styling.mixins.translate("-50%", "-50%"),
				...styling.utils.maxRounded,
			},
		},
	},
	planet: (index, count) => ({
		height: 270,
		width: 270,
		backgroundColor: styling.colors.backgroundCircle,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		...styling.utils.maxRounded,
		...styling.mixins.margin(40, "auto"),
		[styling.sizes.breakpoints.medium]: {
			position: "absolute",
			top: 0,
			left: 0,
			height: 840,
			width: 840,
			backgroundColor: styling.colors.transparent,
			pointerEvents: "none",
			...styling.mixins.margin(0, "auto"),
			...styling.mixins.transition("transform"),
			...styling.mixins.animation(
				spinKeyframes[`spin-${index}-${count}`],
				100,
				"linear",
				"infinite"
			),
			"> a": {
				...styling.mixins.animation(
					spinKeyframes[`counterspin-${index}-${count}`],
					100,
					"linear",
					"infinite"
				),
			},
			":before": {
				content: '""',
				position: "absolute",
				height: 270,
				width: 270,
				top: "50%",
				left: "50%",
				backgroundColor: styling.colors.backgroundCircle,
				...styling.mixins.translate("-50%", "-50%"),
				...styling.utils.maxRounded,
			},
		},
	}),
	planetContent: {
		height: 270,
		width: 270,
		color: styling.colors.linkDefault,
		position: "relative",
		zIndex: 1,
		textAlign: "center",
		fontSize: "2rem",
		lineHeight: "2rem",
		fontWeight: 700,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		pointerEvents: "all",
		...styling.utils.maxRounded,
		...styling.mixins.transition("color"),
		":hover": {
			color: styling.colors.linkInteract,
		},
	},
	orderHint: {
		fontSize: "1.2rem",
		lineHeight: "1.2rem",
		fontWeight: 700,
		...styling.mixins.margin(0, 0, 24),
	},
	arrow: {
		...styling.mixins.margin(24, 0, 0),
		path: {
			fill: styling.colors.linkInteract,
		},
	},
};
