import React from "react";
import { arrayOf, exact, number, string } from "prop-types";

import styling from "Services/styling";
import Styles from "./styles.css";

const headerImages = {
	hiring: [
		{
			id: "mailout_list",
			style: {
				right: 21,
				top: 95,
				...styling.mixins.borderRadius(8),
			},
		},
		{
			id: "mailout_profile",
			style: {
				right: 0,
				top: 35,
				...styling.mixins.borderRadius(4),
			},
		},
	],
	availability: [
		{
			id: "calendar",
			style: {
				right: 22,
				top: 95,
				...styling.mixins.borderRadius(8),
			},
		},
		{
			id: "calendar_key",
			style: {
				right: 0,
				top: 243,
				...styling.mixins.borderRadius(4),
			},
		},
	],
	"staff-management": [
		{
			id: "list_of_profilesa",
			style: {
				right: 14,
				top: 77,
				...styling.mixins.borderRadius(8),
			},
		},
		{
			id: "average_nps",
			style: {
				right: 54,
				top: 313,
				...styling.mixins.borderRadius(4),
			},
		},
		{
			id: "profile_1",
			style: {
				right: 0,
				top: 187,
				...styling.mixins.borderRadius(4),
			},
		},
	],
	training: [
		{
			id: "calendar",
			style: {
				right: 22,
				top: 87,
				...styling.mixins.borderRadius(8),
			},
		},
		{
			id: "training_card_hover",
			style: {
				right: 132,
				top: 391,
				...styling.mixins.borderRadius(4),
				":before": {
					content: '""',
					position: "absolute",
					height: 0,
					width: 0,
					top: 18,
					left: -18,
					...styling.mixins.border(
						[12, "solid", styling.colors.transparent],
						[9, "solid", "#CED6E0"],
						[12, "solid", styling.colors.transparent],
						[9, "solid", styling.colors.transparent]
					),
				},
				":after": {
					content: '""',
					position: "absolute",
					height: 0,
					width: 0,
					top: 18,
					left: -17,
					...styling.mixins.border(
						[12, "solid", styling.colors.transparent],
						[9, "solid", "#FFF"],
						[12, "solid", styling.colors.transparent],
						[9, "solid", styling.colors.transparent]
					),
				},
			},
		},
	],
	scheduling: [
		{
			id: "mobile",
			style: {
				right: 120,
				top: 35,
				...styling.mixins.borderRadius(32),
			},
		},
		{
			id: "drag",
			style: {
				right: 418,
				top: 474,
				boxShadow: "none",
			},
		},
	],
	evaluations: [
		{
			id: "evaluation_overview",
			style: {
				right: 23,
				top: 37,
				...styling.mixins.borderRadius(8),
			},
		},
		{
			id: "nps_per_class",
			style: {
				right: 0,
				top: 270,
				...styling.mixins.borderRadius(4),
			},
		},
	],
	"data-dashboards": [
		{
			id: "resort_average",
			style: {
				right: 14,
				top: 78,
				...styling.mixins.borderRadius(8),
			},
		},
		{
			id: "nps",
			style: {
				right: 0,
				top: 258,
				...styling.mixins.borderRadius(4),
			},
		},
	],
	"relive-your-day": [
		{
			id: "lesson_history",
			style: {
				right: 330,
				top: 20,
				...styling.mixins.transformOrigin("100%", 0),
				...styling.mixins.scale(0.58),
				...styling.mixins.borderRadius(36),
			},
		},
		{
			id: "email",
			style: {
				right: 0,
				top: 20,
				...styling.mixins.transformOrigin("100%", 0),
				...styling.mixins.scale(0.58),
				...styling.mixins.borderRadius(36),
			},
		},
		{
			id: "map",
			style: {
				right: 155,
				top: 0,
				...styling.mixins.transformOrigin("100%", 0),
				...styling.mixins.scale(0.65),
				...styling.mixins.borderRadius(36),
			},
		},
	],
	"mountain-view": [
		{
			id: "desktopb",
			style: {
				right: 14,
				top: 77,
				...styling.mixins.borderRadius(8),
			},
		},
		{
			id: "profile",
			style: {
				right: 0,
				top: 203,
				...styling.mixins.borderRadius(4),
			},
		},
	],
	"class-management": [
		{
			id: "digital_class_list",
			style: {
				right: 14,
				top: 77,
				...styling.mixins.borderRadius(8),
			},
		},
		{
			id: "information",
			style: {
				right: 0,
				top: 314,
				...styling.mixins.borderRadius(4),
			},
		},
	],
};

const ProductHeaderImage = ({ slug, images }) => (
	<div css={Styles.wrapper}>
		{headerImages[slug] &&
			headerImages[slug].map((headerImage) => {
				const foundImage = images.find(
					(image) => image.node.name === headerImage.id
				);
				if (!foundImage) {
					return null;
				}
				return (
					<div
						key={foundImage.node.name}
						css={Styles.image(foundImage.node, headerImage.style)}
					/>
				);
			})}
	</div>
);

ProductHeaderImage.propTypes = {
	slug: string,
	images: arrayOf(
		exact({
			node: exact({
				name: string,
				childImageSharp: exact({
					original: exact({
						height: number,
						src: string,
						width: number,
					}),
				}),
			}),
		})
	),
};

export default ProductHeaderImage;
