import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Link } from "gatsby";
import React from "react";
import { exact, arrayOf, string, object } from "prop-types";

import { RelatedProductPropTypes } from "~/types";
import Arrow from "Components/Arrow";
import MinPageHeightWrapper from "Components/MinPageHeightWrapper";
import { zeroPadding } from "Services/helper-utils/string";
import { richTextOptions } from "Services/helper-utils/rich-text";
import Styles from "./styles.css";

const RelatedProducts = ({ lang, learnMore, relatedProducts, title }) => (
	<MinPageHeightWrapper>
		<div css={Styles.title}>
			{documentToReactComponents(learnMore.json, richTextOptions)}
		</div>
		<div css={Styles.orbitWrapper}>
			<div css={Styles.sun}>{title}</div>
			{relatedProducts
				.sort((a, b) => a.orderHint - b.orderHint)
				.map((relatedProduct, index) => (
					<div
						key={relatedProduct.slug}
						css={Styles.planet(index, relatedProducts.length)}>
						<Link
							css={Styles.planetContent}
							to={`/${lang}/product/${relatedProduct.slug}/`}>
							<div css={Styles.orderHint}>
								{zeroPadding(relatedProduct.orderHint + 1, 2)}
							</div>
							{relatedProduct.title}
							<div css={Styles.arrow}>
								<Arrow direction="right" />
							</div>
						</Link>
					</div>
				))}
		</div>
	</MinPageHeightWrapper>
);

RelatedProducts.propTypes = {
	lang: string,
	learnMore: exact({
		json: object,
	}),
	relatedProducts: arrayOf(exact(RelatedProductPropTypes)),
	title: string,
};

export default RelatedProducts;
