import { Link, graphql } from "gatsby";
import { arrayOf, number, exact, string, object } from "prop-types";
import React from "react";

import {
	ImagePropTypes,
	ImageArrayPropTypes,
	KeyFeaturePropTypes,
	MenuItemPropType,
	CallToActionPropTypes,
	RelatedProductPropTypes,
	TestimonialPropTypes,
} from "~/types";
import Arrow from "Components/Arrow";
import CallToAction from "Components/CallToAction";
import CircularImage from "Components/CircularImage";
import KeyFeatures from "Components/KeyFeatures";
import MinPageHeightWrapper from "Components/MinPageHeightWrapper";
import Testimonial from "Components/Testimonial";
import { EMPTY_ARR } from "Services/constants/empty";
import { zeroPadding } from "Services/helper-utils/string";
import withDisplayAware from "Services/withDisplayAware";
import ProductHeaderImage from "./elements/ProductHeaderImage";
import RelatedProducts from "./elements/RelatedProducts";
import Styles from "./styles.css";

const headerCircleImageProps = {
	small: {
		offsetX: 10,
		size: 600,
	},
	medium: {
		offsetX: 0,
		size: 600,
	},
	large: {
		offsetX: -20,
		size: 600,
	},
	xlarge: {
		size: 600,
	},
};

const getProductPageUrl = (currentProductSlug, products, direction) => {
	const foundIndex = products.findIndex(
		(product) => product.node.slug === currentProductSlug
	);
	if (foundIndex < 0) {
		return products[0].node.slug;
	}
	const newIndex = foundIndex + direction;
	if (newIndex < 0) {
		return products[products.length - 1].node.slug;
	} else if (newIndex >= products.length) {
		return products[0].node.slug;
	}
	return products[newIndex].node.slug;
};

const PageProductContent = (props) => {
	const {
		lang,
		title,
		strap,
		image,
		images,
		keyFeatures,
		size,
		slug,
		page_product,
		products,
		orderHint,
		learnMore,
		relatedProducts,
	} = props;
	const pageProduct = page_product ? page_product[0] : EMPTY_ARR;
	return (
		<>
			<MinPageHeightWrapper style={Styles.wrapper}>
				{size === "xlarge" && (
					<ProductHeaderImage slug={slug} images={images} />
				)}
				<div css={Styles.paddingWrapper}>
					<div css={Styles.headingWrapper}>
						<span css={Styles.orderHint}>{zeroPadding(orderHint + 1, 2)}</span>
						<h2 css={Styles.heading}>{title}</h2>
					</div>
					<div css={Styles.arrows}>
						<Link
							css={Styles.arrow}
							to={`/${lang}/product/${getProductPageUrl(slug, products, -1)}/`}>
							<Arrow direction="left" />
						</Link>
						<Link
							css={Styles.arrow}
							to={`/${lang}/product/${getProductPageUrl(slug, products, 1)}/`}>
							<Arrow direction="right" />
						</Link>
					</div>
					<p css={Styles.strap}>{strap}</p>
				</div>
				<CircularImage
					{...headerCircleImageProps[size]}
					fluid={image.fluid}
					title={image.title}
				/>
			</MinPageHeightWrapper>
			{keyFeatures && (
				<KeyFeatures features={keyFeatures} images={images} pageSlug={slug} />
			)}
			{pageProduct && pageProduct.testimonial && (
				<Testimonial isFullBlock {...pageProduct.testimonial} />
			)}
			{relatedProducts && (
				<RelatedProducts
					relatedProducts={relatedProducts}
					lang={lang}
					learnMore={learnMore}
					title={title}
				/>
			)}
			{pageProduct && pageProduct.callToAction && (
				<CallToAction {...pageProduct.callToAction} />
			)}
		</>
	);
};

PageProductContent.defaultProps = {
	page_product: EMPTY_ARR,
	products: EMPTY_ARR,
};

PageProductContent.propTypes = {
	lang: string,
	products: arrayOf(MenuItemPropType),
	title: string,
	strap: string,
	slug: string,
	image: exact(ImagePropTypes),
	images: ImageArrayPropTypes,
	orderHint: number,
	size: string,
	learnMore: exact({
		json: object,
	}),
	keyFeatures: arrayOf(exact(KeyFeaturePropTypes)),
	relatedProducts: arrayOf(exact(RelatedProductPropTypes)),
	page_product: arrayOf(
		exact({
			title: string,
			description: exact({
				description: string,
			}),
			testimonial: exact(TestimonialPropTypes),
			callToAction: exact(CallToActionPropTypes),
		})
	),
};

export default withDisplayAware("size")(PageProductContent);

export const query = graphql`
	fragment PageProductQuery on ContentfulPageProduct {
		title
		description {
			description
		}
		testimonial {
			...TestimonialQuery
		}
		callToAction {
			...CallToActionQuery
		}
	}
`;
